import React, { useState, useEffect, useCallback, useRef  } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { arrayRemove, arrayUnion, deleteField, doc, getDoc, serverTimestamp, updateDoc, writeBatch } from 'firebase/firestore';
import { db } from '../../../Universal/firebase';
import Navbar from '../../../Universal/Navbar';
import Tooltip from './ToolTip';
import axios from 'axios';
import { SquareCheck, SquareX, SquareSlash, Square, User, MessageSquareMore, Plus, Minus, YoutubeIcon, ChevronRight, ChevronLeft, ChevronUp, ChevronDown, Flag, ArrowLeft } from 'lucide-react';
import ResponsiveText from './ResponsiveText';
import Loader from '../../../Universal/Loader';

function TeacherStudentResults() {
    const { assignmentId, studentUid } = useParams();
    const [assignmentName, setAssignmentName] = useState('');
    const [results, setResults] = useState(null);
    const [studentName, setStudentName] = useState('');
    const [correctCount, setCorrectCount] = useState(0);
    const [contentHeight, setContentHeight] = useState('auto');
    const [partialCount, setPartialCount] = useState(0);
    const [incorrectCount, setIncorrectCount] = useState(0);
    const navigate = useNavigate();
    const [isRegrading, setIsRegrading] = useState(false);
    const [halfCredit, setHalfCredit] = useState(false);
    const [activeQuestionIndex, setActiveQuestionIndex] = useState(null);
    const questionRefs = React.useRef([]);
    const [useSlider, setUseSlider] = useState(false);
    const [debouncedFeedback, setDebouncedFeedback] = useState({});
    const debouncedUpdateRef = useRef(null);
    const [classId, setClassId] = useState(null);
    const [feedbackStates, setFeedbackStates] = useState({});
    const textareaRefs = useRef({});
    const [isLoadingProgress, setIsLoadingProgress] = useState(false);
    const [progressData, setProgressData] = useState(null);
    const [showProgressModal, setShowProgressModal] = useState(false);

    const [isReplacing, setIsReplacing] = useState(false);







    const loadingModalStyle = {
      position: 'fixed',
      top: '100px',
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: '20'
    };
    
    const loadingModalContentStyle = {
      width: 300,
      height: 180,
      backgroundColor: 'white',
      borderRadius: 10,
      textAlign: 'center',
      padding: 20,
      color: 'white',
      alignItems: 'center',
      justifyContent: 'center'
    };
    




    const handleViewLastSave = async () => {
      setIsLoadingProgress(true);
      try {
        // First fetch both progress and grade docs to ensure we have all needed data
        const progressRef = doc(db, 'assignments(progress)', `${assignmentId}_${studentUid}`);
        const progressDoc = await getDoc(progressRef);
        
        if (progressDoc.exists() && progressDoc.data().status === 'submitted') {
          setProgressData(progressDoc.data());
          setShowProgressModal(true);
        } else {
          alert('No saved progress found');
        }
      } catch (error) {
        console.error('Error fetching progress data:', error);
        alert('Error fetching progress data');
      } finally {
        setIsLoadingProgress(false);
      }
    };
    const handleRenewAccess = async () => {
      try {
        if (!classId) {
          console.error('No classId available');
          return;
        }
    
        // References to all documents we need to modify
        const progressRef = doc(db, 'assignments(progress)', `${assignmentId}_${studentUid}`);
        const gradeRef = doc(db, 'grades', `${assignmentId}_${studentUid}`);
        const studentRef = doc(db, 'students', studentUid);
        
        // Start a batch write to ensure all operations are atomic
        const batch = writeBatch(db);
        
        // 1. Update progress doc status back to in_progress
        batch.update(progressRef, {
          status: 'in_progress',
          lastUpdated: serverTimestamp(),
          // Reset any submission-related fields
          submittedAt: null,
          replacedAt: null
        });
        
        // 2. Delete the grade document completely
        batch.delete(gradeRef);
        
        // 3. Update student's assignment statuses and remove ALL grade information
        const studentUpdates = {
          // Remove from completed assignments
          assignmentsTaken: arrayRemove(assignmentId),
          // Add to in-progress assignments
          assignmentsInProgress: arrayUnion(assignmentId),
          // Remove from paused assignments (if it was there)
          assignmentsPaused: arrayRemove(assignmentId),
          // Remove the grade information from the class-specific grades
          [`class_${classId}.grades.${assignmentId}`]: deleteField(),
          // Remove the grade information from the root-level grades map
          [`grades.${assignmentId}`]: deleteField()
        };
        
        batch.update(studentRef, studentUpdates);
        
        // Commit all changes atomically
        await batch.commit();
        
        // Close the modal before navigating
        setShowProgressModal(false);
        
        // Navigate back to the assignments page
        navigate(`/class/${classId}/assignment/${assignmentId}/TeacherResults`);
    
      } catch (error) {
        console.error('Error renewing access:', error);
        // Provide more specific error feedback to the user
        if (error.code === 'permission-denied') {
          alert('You do not have permission to renew access to this assignment.');
        } else if (error.code === 'not-found') {
          alert('The assignment or student record could not be found.');
        } else {
          alert('Error renewing access. Please try again or contact support if the problem persists.');
        }
      }
    };
    const handleReplaceSubmission = async () => {
      if (!progressData) return;
      setIsReplacing(true);
      
      try {
        // Start batch for atomic operations
        const batch = writeBatch(db);
        
        // References we'll need
        const gradeRef = doc(db, 'grades', `${assignmentId}_${studentUid}`);
        const progressRef = doc(db, 'assignments(progress)', `${assignmentId}_${studentUid}`);
        const studentRef = doc(db, 'students', studentUid);
    
        // Calculate scores based on progress data
        const totalQuestions = progressData.questions.length;
        const maxRawScore = totalQuestions * (progressData.scaleMax - progressData.scaleMin);
    
        // Create the initial grade document structure, following the pattern from handleSubmit
        const initialGradeData = {
          assignmentId,
          studentUid,
          assignmentName,
          firstName: progressData.firstName,
          lastName: progressData.lastName,
          classId,
          halfCreditEnabled: progressData.halfCredit,
          submittedAt: serverTimestamp(),
          questions: progressData.questions.map(q => ({
            questionId: q.questionId,
            question: q.text,
            studentResponse: q.studentResponse || '',
            rubric: q.rubric,
            feedback: 'Responses haven\'t been graded yet',
            score: 0,
            flagged: false
          })),
          viewable: false,
          rawTotalScore: 0,
          maxRawScore,
          scaledScore: 0,
          scaleMin: progressData.scaleMin || 0,
          scaleMax: progressData.scaleMax || 2,
          percentageScore: 0
        };
    
        // Delete old grade and set new one
        batch.delete(gradeRef);
        batch.set(gradeRef, initialGradeData);
    
        // Update progress doc status
        batch.update(progressRef, {
          status: 'submitted',
          submittedAt: serverTimestamp(),
          replacedAt: serverTimestamp()
        });
    
        // Initial update to student's grade reference
        batch.update(studentRef, {
          assignmentsTaken: arrayUnion(assignmentId),
          assignmentsInProgress: arrayRemove(assignmentId),
          assignmentsPaused: arrayRemove(assignmentId),
          [`class_${classId}.grades.${assignmentId}`]: {
            score: 0,
            submittedAt: serverTimestamp(),
            assignmentId,
            assignmentName
          }
        });
    
        // Commit initial changes
        await batch.commit();
    
        // Format questions for grading API, matching the format used in handleSubmit
        const questionsToGrade = progressData.questions.map(q => ({
          questionId: q.questionId,
          question: q.text,
          rubric: q.rubric,
          studentResponse: q.studentResponse || ''
        }));
    
        // Make grading request
        const response = await axios.post('https://us-central1-square-score-ai.cloudfunctions.net/GradeSAQ', {
          questions: questionsToGrade,
          halfCreditEnabled: progressData.halfCredit,
          classId: classId
        });
    
        if (response.status === 200) {
          const gradingResults = response.data;
          
          // Calculate scores following the same pattern as handleSubmit
          const newTotalScore = gradingResults.reduce((sum, grade) => sum + grade.score, 0);
          const newPercentageScore = (newTotalScore / maxRawScore) * 100;
    
          // Create the final graded questions array
          const gradedQuestions = progressData.questions.map((q, index) => ({
            questionId: q.questionId,
            question: q.text,
            studentResponse: q.studentResponse || '',
            rubric: q.rubric,
            feedback: gradingResults[index].feedback,
            score: gradingResults[index].score,
            flagged: false
          }));
    
          // Update the grade document with final results
          await updateDoc(gradeRef, {
            questions: gradedQuestions,
            rawTotalScore: newTotalScore,
            scaledScore: newTotalScore / maxRawScore,
            percentageScore: newPercentageScore,
            viewable: true,
            gradedAt: serverTimestamp()
          });
    
          // Update student's final grade
          await updateDoc(studentRef, {
            [`class_${classId}.grades.${assignmentId}`]: {
              score: newPercentageScore,
              submittedAt: serverTimestamp(),
              assignmentId,
              assignmentName
            }
          });
    
          setShowProgressModal(false);
          window.location.reload();
        }
      } catch (error) {
        console.error('Error replacing submission:', error);
        alert('Error replacing submission. Please try again.');
      } finally {
        setIsReplacing(false);
      }
    };
    const handleBackToSubmission = () => {
      // Simply close the modal to return to the current submission view
      setShowProgressModal(false);
    };



// Add to imports at the top

// Update the ProgressModal component
const ProgressModal = ({ onClose }) => {
  if (!progressData) return null;
  
  return (
  
      <div style={{
     position: 'absolute',
        width: 'calc(100% )',
        top: '180px',
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'white',
        boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
     
        overflow: 'auto',
      }}>
     










        <div style={{ marginTop: '20px', }}>
          {progressData.questions.map((question, index) => (
            <div key={index} style={{ marginBottom: '40px', paddingBottom: '40px',
              paddingLeft: '4%', borderBottom: '1px solid #ededed' 
             }}>
              <div style={{ 
                fontWeight: '600',
                fontSize: '18px',
                marginBottom: '10px',
                color: 'black'
              }}>
                {question.text}
              </div>
              <div style={{
    flexGrow: 0, // Changed from 1 to 0 to prevent full width
    paddingLeft: '30px',
    paddingRight: '0px',
    marginLeft: '0px',
    marginTop: '10px',
    position: 'relative',
    padding: '10px 40px 10px 10px',
    minWidth: '100px',
    maxWidth: 'fit-content', // Added to make it inline
    background: `#f4f4f4`,
    borderLeft: `4px solid grey`, 
    color: `grey`, 
    display: 'inline-flex', // Changed from flex to inline-flex
    alignItems: 'center',
}}>
    <p style={{
        fontSize: '16px',
        fontWeight: '600',
        textAlign: 'left',
        margin: 0,
        width: 'auto', // Changed from 100% to auto
        padding: '0px',
        whiteSpace: 'pre-wrap', // Added to preserve line breaks
        overflowWrap: 'break-word', // Added to handle long words
    }}>
        {question.studentResponse || "Not provided"}
    </p>
</div>
            </div>
          ))}
        </div>
      </div>
   
  );
};



    const pendingUpdateRef = useRef(null);
    const getGradeColors = (grade) => {
        if (grade === undefined || grade === null || grade === 0) return { color: '#858585', background: 'white' };
        if (grade < 50) return { color: '#FF0000', background: '#FFCBCB' };
        if (grade < 70) return { color: '#FF4400', background: '#FFC6A8' };
        if (grade < 80) return { color: '#EFAA14', background: '#FFF4DC' };
        if (grade < 90) return { color: '#9ED604', background: '#EDFFC1' };
        if (grade > 99) return { color: '#E01FFF', background: '#F7C7FF' };
        return { color: '#2BB514', background: '#D3FFCC' };
      };
      
    // Add cleanup effect
    useEffect(() => {
      // Cleanup function that runs before component unmounts or re-renders
      return () => {
        // If there's a pending update in the debounce timer, execute it immediately
        if (debouncedUpdateRef.current) {
          debouncedUpdateRef.current.flush();
        }
    
        // If there's a pending firestore update, execute it immediately
        if (pendingUpdateRef.current) {
          pendingUpdateRef.current();
        }
      };
    }, []);
    
    // Modify handleFeedbackChange to track pending updates
    const handleFeedbackChange = (index, newFeedback) => {
      // Update local state immediately
      setFeedbackStates(prev => ({
        ...prev,
        [index]: newFeedback
      }));
    
      // Cancel any existing debounced update
      if (debouncedUpdateRef.current) {
        debouncedUpdateRef.current.cancel();
      }
    
      // Create the update function
      const updateFunction = async () => {
        try {
          const updatedQuestions = results.questions.map((q, i) => 
            i === index ? { ...q, feedback: newFeedback } : q
          );
    
          const hasFlaggedQuestions = updatedQuestions.some(q => q.flagged);
    
          await updateDoc(doc(db, 'grades', `${assignmentId}_${studentUid}`), {
            questions: updatedQuestions,
            hasFlaggedQuestions
          });
    
          setResults(prev => ({
            ...prev,
            questions: updatedQuestions,
            hasFlaggedQuestions
          }));
    
          // Clear the pending update reference after successful update
          pendingUpdateRef.current = null;
        } catch (error) {
          console.error('Error updating feedback:', error);
        }
      };
    
      // Store the update function in the ref
      pendingUpdateRef.current = updateFunction;
    
      // Create a debounced version that clears the pending ref after execution
      debouncedUpdateRef.current = debounce(() => {
        updateFunction();
      }, 1000);
    
      // Execute the debounced update
      debouncedUpdateRef.current();
    };
    
    // Modify the debounce function to include a flush method
    const debounce = (func, wait) => {
        let timeout;
        let lastArgs;
        let lastThis;
      
        const debouncedFunction = (...args) => {
          lastArgs = args;
          lastThis = this;
      
          const later = () => {
            func.apply(lastThis, lastArgs);
            timeout = null;
            lastArgs = null;
            lastThis = null;
          };
      
          clearTimeout(timeout);
          timeout = setTimeout(later, wait);
        };
      
        debouncedFunction.cancel = () => {
          clearTimeout(timeout);
          timeout = null;
          lastArgs = null;
          lastThis = null;
        };
      
        debouncedFunction.flush = () => {
          if (timeout) {
            func.apply(lastThis, lastArgs);
            debouncedFunction.cancel();
          }
        };
      
        return debouncedFunction;
      };
      
      // Update the cleanup effect
      useEffect(() => {
        return () => {
          // Safely check if the debounced update ref exists and has a flush method
          if (debouncedUpdateRef.current?.flush) {
            debouncedUpdateRef.current.flush();
          }
      
          // If there's a pending update, execute it immediately
          if (pendingUpdateRef.current) {
            pendingUpdateRef.current();
          }
        };
      }, []);
      
      // Update how we create the debounced update reference
      useEffect(() => {
        const debouncedUpdate = debounce((index, newScore, newFeedback) => {
          updateGradeAndFeedback(index, newScore, newFeedback);
        }, 1000);
      
        debouncedUpdateRef.current = debouncedUpdate;
      
        return () => {
          if (debouncedUpdateRef.current?.cancel) {
            debouncedUpdateRef.current.cancel();
          }
        };
      }, []);



    const scrollToQuestion = (index) => {
        setActiveQuestionIndex(index);
        questionRefs.current[index]?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    };

    const handleQuestionClick = (questionId) => {
        navigate(`/class/${classId}/assignment/${assignmentId}/TeacherResults/`, {
          state: {
            targetTab: 'questionBank',
            targetQuestionId: questionId,
            openQuestionResults: true
          }
        });
      };

    const contentRef = useRef(null);
    const [isMapCollapsed, setIsMapCollapsed] = useState(false);

    const stickyRef = useRef(null);
    const containerRef = useRef(null);

    const getQuestionIcon = (score, scaleMax, scaleMin) => {
        if (score === scaleMax) {
            return <SquareCheck size={20} color="#00d12a" />;
        } else if (score === scaleMin) {
            return <SquareX size={20} color="#FF0000" />;
        } else {
            return <SquareSlash size={20} color="#FFD13B" />;
        }
    };

    useEffect(() => {
        const checkContentHeight = () => {
          if (contentRef.current) {
            const windowHeight = window.innerHeight;
            const contentHeight = contentRef.current.scrollHeight;
            setUseSlider(contentHeight > windowHeight - 230); // 230 = 180 (top) + 50 (header)
          }
        };
    
        checkContentHeight();
        window.addEventListener('resize', checkContentHeight);
        return () => window.removeEventListener('resize', checkContentHeight);
      }, [results]);

    // Debounce Feedback Updates
    useEffect(() => {
        debouncedUpdateRef.current = debounce((index, newScore, newFeedback) => {
            updateGradeAndFeedback(index, newScore, newFeedback);
        }, 5000);

        // Cleanup function
        return () => {
            if (debouncedUpdateRef.current) {
                debouncedUpdateRef.current.cancel();
            }
        };
    }, []);

    useEffect(() => {
        // Initialize feedback states from results
        if (results?.questions) {
          const initialFeedback = {};
          results.questions.forEach((question, index) => {
            initialFeedback[index] = question.feedback || '';
          });
          setFeedbackStates(initialFeedback);
        }
      }, [results]);

    // Initialize feedback states and set initial heights
    useEffect(() => {
        if (results?.questions) {
          const initialFeedback = {};
          results.questions.forEach((question, index) => {
            initialFeedback[index] = question.feedback || '';
          });
          setFeedbackStates(initialFeedback);
      
          // Set initial heights after a short delay to ensure content is rendered
          setTimeout(() => {
            results.questions.forEach((_, index) => {
              const textarea = textareaRefs.current[index];
              if (textarea) {
                textarea.style.height = '24px'; // Set to one line initially
                textarea.style.height = textarea.value ? `${textarea.scrollHeight}px` : '24px';
              }
            });
          }, 0);
        }
      }, [results]);


    // Fetch Results on Component Mount
    useEffect(() => {
        const fetchResults = async () => {
            try {
                const gradeDocRef = doc(db, 'grades', `${assignmentId}_${studentUid}`);
                const gradeDoc = await getDoc(gradeDocRef);
    
                if (gradeDoc.exists()) {
                    const data = gradeDoc.data();
                    setResults(data);
                    setAssignmentName(data.assignmentName);
                    
                    setClassId(data.classId);
                    const correct = data.questions.filter(q => q.score === data.scaleMax).length;
                    const partial = data.questions.filter(q => q.score > data.scaleMin && q.score < data.scaleMax).length;
                    const incorrect = data.questions.filter(q => q.score === data.scaleMin).length;
                    
                    setCorrectCount(correct);
                    setPartialCount(partial);
                    setIncorrectCount(incorrect);
                    
                    const studentDocRef = doc(db, 'students', studentUid);
                    const studentDoc = await getDoc(studentDocRef);
                    
                    if (studentDoc.exists()) {
                        const studentData = studentDoc.data();
                        setStudentName(`${studentData.firstName} ${studentData.lastName}`);
                        console.log("Student Name:", `${studentData.firstName} ${studentData.lastName}`); // Added log statement
                    } else {
                        console.log("Student document does not exist");
                    }
                }
            } catch (error) {
                console.error('Error fetching results:', error);
            }
        };
    
        fetchResults();
    }, [assignmentId, studentUid]);

    // Handle Regrading Assignment
    const handleRegrade = async () => {
        if (window.confirm("Are you sure you want to regrade this assignment? This will replace the current grades.")) {
            setIsRegrading(true);
            try {
                const questionsToGrade = results.questions.map(q => ({
                    question: q.question,
                    rubric: q.rubric,
                    studentResponse: q.studentResponse
                }));

                const response = await axios.post('https://us-central1-square-score-ai.cloudfunctions.net/GradeSAQ', {
                  questions: questionsToGrade,
                  halfCreditEnabled: halfCredit,
                  classId: classId 
                });

                if (response.status === 200) {
                    const newGrades = response.data;
                    
                    const newTotalScore = newGrades.reduce((sum, grade) => sum + grade.score, 0);
                    const maxRawScore = results.questions.length * 2;
                    const newScaledScore = ((newTotalScore / maxRawScore) * (results.scaleMax - results.scaleMin)) + results.scaleMin;
                    const newPercentageScore = ((newScaledScore - results.scaleMin) / (results.scaleMax - results.scaleMin)) * 100;

                    const updatedQuestions = results.questions.map((q, index) => ({
                        ...q,
                        score: newGrades[index].score,
                        feedback: newGrades[index].feedback,
                        flagged: false, // Reset flag after regrading
                    }));

                    // Determine if any questions are still flagged
                    const hasFlaggedQuestions = updatedQuestions.some(q => q.flagged);

                    const gradeDocRef = doc(db, 'grades', `${assignmentId}_${studentUid}`);
                    await updateDoc(gradeDocRef, {
                        questions: updatedQuestions,
                        rawTotalScore: newTotalScore,
                        scaledScore: newScaledScore,
                        percentageScore: newPercentageScore,
                        hasFlaggedQuestions: hasFlaggedQuestions,
                        halfCreditEnabled: halfCredit
                    });

                    setResults(prevResults => ({
                        ...prevResults,
                        questions: updatedQuestions,
                        rawTotalScore: newTotalScore,
                        scaledScore: newScaledScore,
                        percentageScore: newPercentageScore,
                        hasFlaggedQuestions: hasFlaggedQuestions,
                        halfCreditEnabled: halfCredit
                    }));

                    const newCorrectCount = updatedQuestions.filter(q => q.score === results.scaleMax).length;
                    const newPartialCount = halfCredit ? updatedQuestions.filter(q => q.score > results.scaleMin && q.score < results.scaleMax).length : 0;
                    const newIncorrectCount = updatedQuestions.filter(q => q.score === results.scaleMin).length;
                    
                    setCorrectCount(newCorrectCount);
                    setPartialCount(newPartialCount);
                    setIncorrectCount(newIncorrectCount);

                    alert("Assignment regraded successfully!");
                }
            } catch (error) {
                console.error("Error regrading assignment:", error);
                alert("An error occurred while regrading the assignment. Please try again.");
            } finally {
                setIsRegrading(false);
            }
        }
    };
    const updateCounts = (questions) => {
      const correct = questions.filter(q => q.score === results.scaleMax).length;
      const partial = questions.filter(q => q.score > results.scaleMin && q.score < results.scaleMax).length;
      const incorrect = questions.filter(q => q.score === results.scaleMin).length;
      
      setCorrectCount(correct);
      setPartialCount(partial);
      setIncorrectCount(incorrect);
    };
    // Function to update a student's grade and feedback
    const updateGradeAndFeedback = async (index, newScore, feedback) => {
      if (!results) return;
    
      try {
        const updatedQuestions = [...results.questions];
        updatedQuestions[index] = {
          ...updatedQuestions[index],
          score: Math.max(results.scaleMin, Math.min(results.scaleMax, newScore)),
          feedback: feedback
        };
    
        const newRawTotal = updatedQuestions.reduce((sum, q) => sum + q.score, 0);
        const maxScore = results.rawMaxScore || (updatedQuestions.length * 2);
        const newPercentageScore = (newRawTotal / maxScore) * 100;
        const newScaledScore = (newRawTotal / maxScore);
        const hasFlaggedQuestions = updatedQuestions.some(q => q.flagged);
    
        const batch = writeBatch(db);
        const gradeRef = doc(db, 'grades', `${assignmentId}_${studentUid}`);
        const studentRef = doc(db, 'students', studentUid);
    
        // Update grade document
        batch.update(gradeRef, {
          questions: updatedQuestions,
          rawTotalScore: newRawTotal,
          percentageScore: newPercentageScore,
          scaledScore: newScaledScore,
          hasFlaggedQuestions
        });
    
        // Update student's class grades
        batch.update(studentRef, {
          [`class_${classId}.grades.${assignmentId}`]: {
            score: newPercentageScore,
            submittedAt: results.submittedAt,
            assignmentId,
            assignmentName,
          }
        });
    
        await batch.commit();
    
        // Update local state
        setResults({
          ...results,
          questions: updatedQuestions,
          rawTotalScore: newRawTotal,
          percentageScore: newPercentageScore,
          scaledScore: newScaledScore,
          hasFlaggedQuestions
        });
    
        // Update counts
        updateCounts(updatedQuestions);
    
      } catch (error) {
        console.error('Error updating grade and feedback:', error);
      }
    };

    // Toggle flag status for a specific question
    const toggleFlag = async (index) => {
        if (!results) return;

        const updatedQuestions = [...results.questions];
        updatedQuestions[index].flagged = !updatedQuestions[index].flagged;

        // Determine if any questions are still flagged
        const hasFlaggedQuestions = updatedQuestions.some(q => q.flagged);

        try {
            await updateDoc(doc(db, 'grades', `${assignmentId}_${studentUid}`), {
                questions: updatedQuestions,
                hasFlaggedQuestions: hasFlaggedQuestions // Update flag status
            });

            setResults({
                ...results,
                questions: updatedQuestions,
                hasFlaggedQuestions: hasFlaggedQuestions
            });
        } catch (error) {
            console.error('Error toggling flag:', error);
        }
    };

    // Handle navigation clicks
    const handleGradeClick = (studentUid) => {
        navigate(`/teacherStudentResults/${assignmentId}/${studentUid}`);
    };
    const handleStudentClick = (studentUid) => {
        navigate(`/class/${classId}/student/${studentUid}/grades`);
    };
    const handleAssignmentClick = () => {
        navigate(`/class/${classId}/assignment/${assignmentId}/TeacherResults`);
    };

    // Determine letter grade based on percentage
    const getLetterGrade = (percentage) => {
        if (percentage >= 90) return 'A';
        if (percentage >= 80) return 'B';
        if (percentage >= 70) return 'C';
        if (percentage >= 60) return 'D';
        return 'F';
    };

    // Render Loading State
    if (!results) {
        return <div>Loading...</div>;
    }
    const letterGrade = getLetterGrade(results.percentageScore);

    return (
        <div style={{   minHeight: '100vh',
            width: 'calc(100% - 200px)',
            marginLeft:'200px',
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            position: 'relative' }}>
<Navbar 
  userType="teacher"
  navItems={[
    {
      type: 'assignmentName',
      id: assignmentId,
      label: assignmentName
    },
    {
      type: 'studentGrades',
      id: studentUid,
      label: studentName
    }
  ]}
  classId={classId}
/>


{(isRegrading || isReplacing) && (
  <div style={loadingModalStyle}>
    <div style={loadingModalContentStyle}>
      <p style={{ 
        fontSize: '20px', 
        fontFamily: "'montserrat', sans-serif", 
        fontWeight: '500', 
        justifyContent: 'center',
        position: 'absolute', 
        color: 'black', 
        top: '45%', 
        left: '50%', 
        transform: 'translate(-50%, -45%)', 
        marginBottom: '0px' 
      }}>
        {isRegrading ? 'Regrading Assignment' : 'Replacing Submission'}
      </p>
      <Loader/>
    
    </div>
  </div>
)}


            <div style={{  fontFamily: "'montserrat', sans-serif", backgroundColor: '', width: '100%', zIndex: '20', alignItems: 'center', marginLeft: 'auto', marginRight: 'auto', marginTop: '0px'}}>
           
           
           
       
               
                  
                  
            <div style={{display: 'flex'}}>
                        <div style={{
                            paddingRight: '0px',
                            width: '655px',
                            borderRadius: '15px',
                            marginBottom: '20px',
                            height: '140px',
                            marginLeft: '4%',
                        }}>


                            <div style={{display: 'flex', marginBottom: '30px'}}>
                                <h1
                                   onClick={() => navigate(`/class/${classId}/student/${studentUid}/grades`)}
                           
                                    style={{
                                        fontSize: '30px',
                                        color: 'black',
                                        marginBottom: '-10px',
                                        cursor: 'pointer',
                                        marginLeft: '-5px',
                                        fontFamily: "'montserrat', sans-serif",
                                        textAlign: 'left',
                                        fontWeight: '500'
                                    }}
                                    onMouseEnter={(e) => { e.target.style.textDecoration = 'underline'; }}
                                    onMouseLeave={(e) => { e.target.style.textDecoration = 'none'; }}
                                >
                                    {studentName} 
                                </h1>



                             

                                
                           
                            </div>
    
            <div style={{display: 'flex',  height: '26px'}}>

<div style={{    width: '30px',
                            textAlign: 'center',
                            height: '20px',
                            marginTop: '3px',
                            lineHeight: '20px',
                            fontSize: '16px',
                            borderRadius: '3px',
                                background: getGradeColors(results.percentageScore).background,
                                color: getGradeColors(results.percentageScore).color
                            }}>{letterGrade}</div>
                            <div style={{height: '20px', width: '1px', background: '#EDEDED ', margin: '3px 10px'}}/>
                        <h1 style={{
                            fontSize: '16px',
                            color: 'grey',
                            marginBottom: '0px',
                            cursor: 'pointer',
                            marginTop: '3px',
                            fontFamily: "'montserrat', sans-serif",
                            textAlign: 'left',
                            fontWeight: '500'
                        }}
                        onClick={() => navigate(`/class/${classId}/assignment/${assignmentId}/TeacherResults`)}
                               
                     onMouseEnter={(e) => { e.target.style.textDecoration = 'underline'; }}
                        onMouseLeave={(e) => { e.target.style.textDecoration = 'none'; }}
                        >
                          {assignmentName} 
                        </h1>
                        <div style={{height: '20px', width: '1px', background: '#EDEDED ', margin: '3px 10px'}}/>
                   
<span style={{fontSize: '14px', fontWeight: '600', color: '#38BFB8',
                            marginTop: '4px', }}>OE</span>
<div style={{height: '20px', width: '1px', background: '#EDEDED ', margin: '3px 10px'}}/>
                   
                        <h1 style={{fontSize: '14px', color: 'grey', 
                            marginTop: '4px', fontWeight: '500'}}>
                                 {new Date(results.submittedAt.toDate()).toLocaleString()}
                            </h1>


                          
                        </div>
                        <button
    onClick={handleViewLastSave}
    style={{
      padding: '5px 10px',
      backgroundColor: 'white',
      color: 'grey',
      border: '1px solid lightgrey',
      borderRadius: '5px',
      position: 'absolute', top: '20px', right: '4%',
      cursor: 'pointer',
      fontFamily: "'montserrat', sans-serif", 
    }}
  >
   View last save
  </button>
                    </div>
     
                    </div>
                    </div>
          
               {showProgressModal && (
               <div style={{
                position: 'sticky',
                height: '60px',
                top: '0px',
                left: '200px',
                width: 'calc(100%)',
                background: 'rgb(255,255,255,.9)',
                backdropFilter: 'blur(5px)',
                borderBottom: '1px solid lightgrey',
                
                borderTop: '1px solid #ededed',
              marginTop: '-40px',
                transition: 'all 0.3s',
                zIndex: 21,
                display: 'flex',
                marginBottom: '-13px',
                flexDirection: 'row'
    }}>
    <div style={{marginLeft: 'calc(4% - 10px)',
      
      width: '92.4%', display: 'flex', overflow: 'hidden', marginTop: '6px', }}>
      <button
      style={{position: 'absolute', left: '1%', top: '16px', color: 'grey', cursor: isRegrading || isReplacing ? 'not-allowed' : 'pointer',
        background: 'transparent', width: '30px', border: ' none'
      }}
      onClick={() => setShowProgressModal(false)}
      disabled={isRegrading || isReplacing}
      >
    <ArrowLeft 
    size={16}
    
    style={{
      marginTop: '4px'

      

    }}/></button>
          <h2 style={{ marginLeft: '5px', fontWeight: '500', fontSize: '16px', marginTop: '14px',  }}>Last Saved Progress</h2>
          <div style={{ display: 'flex', gap: '10px',   marginLeft: 'auto',  }}>
            <button
              onClick={() => handleRenewAccess()}
              disabled={isRegrading || isReplacing}
              style={{
                padding: '5px 16px',
                backgroundColor: '#F0F9FF',
                color: '#020CFF',
              
                marginTop: '8px', 
                height: '30px',
                border: '1px solid #020CFF',
                borderRadius: '5px',
                cursor: isRegrading || isReplacing ? 'not-allowed' : 'pointer',
                fontFamily: "'montserrat', sans-serif",
                fontWeight: '500',
                opacity: isRegrading || isReplacing ? 0.7 : 1
              }}
            >
              Renew Access
            </button>
            <button
              onClick={() => handleReplaceSubmission()}
              disabled={isRegrading || isReplacing}
              style={{  padding: '5px 16px',
                height: '30px',
                marginTop: '8px', 
                backgroundColor: '#FFF5F5',
                color: '#FF0000',
                border: '1px solid #FF0000',
                borderRadius: '5px',
                cursor: isRegrading || isReplacing ? 'not-allowed' : 'pointer',
                fontFamily: "'montserrat', sans-serif",
                fontWeight: '500',
                opacity: isRegrading || isReplacing ? 0.7 : 1
              }}
            >
              Replace Submission
            </button>
 
          </div>
        </div>
</div>
               )}



               <div style={{
      position: 'sticky',
      height: '50px',
      top: '0px',
      left: '200px',
      width: 'calc(100%)',
      background: 'rgb(255,255,255,.9)',
      backdropFilter: 'blur(5px)',
      borderBottom: '1px solid lightgrey',
      
      borderTop: '1px solid lightgrey',
    marginTop: '-40px',
      transition: 'all 0.3s',
      zIndex: 20,
      display: 'flex',
      flexDirection: 'row'
    }}>
     <div style={{marginLeft: 'calc(4% - 10px)', width: '86%', display: 'flex', overflow: 'hidden', marginTop: '2px' }}>
      {results.questions.map((question, index) => (
          <div
            key={index}
            onClick={() => scrollToQuestion(index)}
            style={{
              width: '30px',

           cursor: 'pointer',
              padding: '10px 5px',
            }}
          >
            {question.score === results.scaleMax ? (
              <SquareCheck size={25} color="#00d12a" style={{ marginRight: '0px',
                cursor: 'pointer', }} />
            ) : question.score === results.scaleMin ? (
              <SquareX size={25} color="#FF0000" style={{ marginRight: '0px' ,
                cursor: 'pointer',}} />
            ) : (
              <SquareSlash size={25} color="#FFD13B" style={{ marginRight: '0px',
                cursor: 'pointer', }} />
            )}
          </div>
         ))}
           </div>  
           <div style={{fontSize: '20px', marginRight: '4%', marginLeft: 'auto', paddingLeft: '20px', lineHeight: '50px', display: 'flex'
            
           }}>

          

<div style={{
                                width: '60px',
                                textAlign: 'center',
                                height: '26px',
                                marginTop: '12px',
                                lineHeight: '26px',
                                fontSize: '16px',
                                borderRadius: '5px',

background: getGradeColors(results.percentageScore.toFixed(0)).background,
color: getGradeColors(results.percentageScore.toFixed(0)).color 
          }}>
{results.percentageScore.toFixed(0)}%
            </div>
            
            
           </div>
            </div>

            
       

                 
              
              
       
                
              
                <ul style={{ listStyle: 'none', padding: '0', marginTop: '0px',  width: '100%',marginLeft: 'auto', marginRight: 'auto', borderRadius: '20px' }}>
                    {results.questions && results.questions.map((question, index) => {
                        const studentResponseLength = (question.studentResponse || "").length;
                        const isShortResponse = studentResponseLength < 50;
                        const studentResponseWidth = isShortResponse ? 280 : 380;
                        const feedbackWidth = isShortResponse ? 540 : 440;
                        
                        return (
                            <li key={index} 
                                ref={el => questionRefs.current[index] = el} 
                                style={{ position: 'relative', fontFamily: "'montserrat', sans-serif", marginBottom: '20px', background: 'white',
                                     width: '92%', marginRight: 'auto', paddingLeft: '4%',paddingRight: '4%', borderBottom: ' 1px solid lightgrey',  marginTop: '0px',   paddingBottom:'35px', marginLeft: '-5px' }}>
                             <div style={{ display: 'flex', fontFamily: "'montserrat', sans-serif", alignItems: 'center' }}>
                      
        
        {/* Question Text */}
        <button
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        handleQuestionClick(question.questionId);
    }}
    style={{ 
        width: 'calc(100%)', 
        backgroundColor: 'white', 
        lineHeight: '1.4', 
        fontSize: '20px', 
        textAlign: 'left', 
        border: 'none', 
        position: 'relative', 
        display: 'flex', 
        flexDirection: 'column', 
        cursor: 'pointer',
        padding: '0',
        fontFamily: 'inherit'
    }}
>
    <ResponsiveText
        text={question.question}
        maxFontSize={20} 
        minFontSize={14} 
    />
</button>
        
        {/* Score Buttons and Flag Button */}
        <div style={{ 
            display: 'flex', 
            alignItems: 'center', 
            marginLeft: 'auto',
            gap: '0px' 
        }}>
            {/* Score 2 Button */}
            <button
                onClick={() => updateGradeAndFeedback(index, 2, question.feedback)}
                style={{
                    border: 'none',
                    background: 'none',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                title="Assign Score: 2"
                aria-label="Assign Score 2"
            >
                <SquareCheck 
                    size={24} 
                    style={{
                        padding: '4px',
                        borderRadius: '5px',
                        border: '2px solid',
                        color: question.score === 2 ? '#16a34a' : '#9ca3af',
                        borderColor: question.score === 1 ? 'white' : 'white',
                        transition: 'background-color 0.3s, color 0.3s, border-color 0.3s'
                    }}
                />
            </button>

            {/* Score 1 Button */}
            <button
                onClick={() => updateGradeAndFeedback(index, 1, question.feedback)}
                style={{
                    border: 'none',
                    background: 'none',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                title="Assign Score: 1"
                aria-label="Assign Score 1"
            >
                <SquareSlash 
                    size={24}
                    style={{
                        padding: '4px',
                        borderRadius: '5px',
                        border: '2px solid',
                        color: question.score === 1 ? '#FFD13B' : '#9ca3af',
                        borderColor: question.score === 1 ? 'white' : 'white',
                        transition: 'background-color 0.3s, color 0.3s, border-color 0.3s'
                    }}
                />
            </button>

            {/* Score 0 Button */}
            <button
                onClick={() => updateGradeAndFeedback(index, 0, question.feedback)}
                style={{
                    border: 'none',
                    background: 'none',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
                title="Assign Score: 0"
                aria-label="Assign Score 0"
            >
                <SquareX 
                    size={24} 
                    style={{
                        padding: '4px',
                        borderRadius: '5px',
                        border: '2px solid',
                        color: question.score === 0 ? '#dc2626' : '#9ca3af',
                        borderColor: question.score === 1 ? 'white' : 'white',
                        transition: 'background-color 0.3s, color 0.3s, border-color 0.3s'
                    }}
                />
            </button>

            {/* Flag Button */}
            <button
                onClick={() => toggleFlag(index)}
                style={{
                    padding: '4px',
                    marginLeft: '20px',
                    cursor: 'pointer',
                    borderRadius: '5px',
                    border: '2px solid',
                    background:'white',
                    color: question.flagged ? '#020CFF' : '#9ca3af',
                    borderColor: question.score === 1 ? 'white' : 'white',
                    transition: 'background-color 0.3s, color 0.3s, border-color 0.3s'
                }}
                title="Flag this question"
                aria-label={question.flagged ? "Unflag this question" : "Flag this question"}
            >
                <Flag size={20} />
            </button>
        </div>
                                </div>
                                <div style={{ marginTop: '0px'}}>
                               
                                    
                                <div style={{
    flexGrow: 0, // Changed from 1 to 0 to prevent full width
    paddingLeft: '30px',
    paddingRight: '0px',
    marginLeft: '0px',
    position: 'relative',
    padding: '10px 40px 10px 10px',
    minWidth: '100px',
    maxWidth: 'fit-content', // Added to make it inline
    background: `${question.score === 2 ? '#CCFFC3' : question.score === 1 ? '#FFF5D2' : '#FFCDCD'}`,
    borderLeft: `4px solid ${question.score === 2 ? '#20BF00' : question.score === 1 ? '#F4C10A' : '#FF0000'}`, 
    color: `${question.score === 2 ? '#20BF00' : question.score === 1 ? '#E76F00' : '#FF0000'}`, 
    display: 'inline-flex', // Changed from flex to inline-flex
    alignItems: 'center',
}}>
    <p style={{
        fontSize: '16px',
        fontWeight: '600',
        textAlign: 'left',
        margin: 0,
        width: 'auto', // Changed from 100% to auto
        padding: '0px',
        whiteSpace: 'pre-wrap', // Added to preserve line breaks
        overflowWrap: 'break-word', // Added to handle long words
    }}>
        {question.studentResponse || "Not provided"}
    </p>
</div>

                                 
<div
                    style={{
                      alignItems: 'center',
                      marginBottom: '10px',
                      fontWeight: '500',  color: 'lightgrey',
                      marginTop: '25px' ,
                    
    textAlign: 'left',
    width: '96%',
                      display: 'flex',
                    }}
                  >
                    <MessageSquareMore size={18} style={{ marginRight: '10px' }} />
                    <div
                      style={{
                        margin: 0,
                        fontWeight: '500',
                        display: 'flex', fontSize: '14px',
                        alignItems: 'center',color: 'lightgrey',
                      }}
                    >
                      Feedback
                
                    </div>
                  </div>
                  <textarea
  style={{
    fontSize: '16px',
    color: 'grey',
    textAlign: 'left',
    width: '100%',
    border: 'none',
    resize: 'none',
    overflow: 'hidden',
    fontFamily: "'montserrat', sans-serif",
    marginTop: '-5px',
    background: 'transparent',
    lineHeight: '1.5',
    minHeight: '24px' // This sets exactly one line height (16px * 1.5)
  }}
  value={feedbackStates[index] || ''}
  onChange={(e) => {
    e.target.style.height = '24px'; // Reset to one line
    e.target.style.height = `${e.target.scrollHeight}px`;
    handleFeedbackChange(index, e.target.value);
  }}
  placeholder="Enter feedback here..."
  onFocus={(e) => {
    e.target.style.height = '24px'; // Reset to one line
    e.target.style.height = `${e.target.scrollHeight}px`;
  }}
/>
                

              
                                </div>
                            </li>
                        );
                    })}
                </ul>
                <div style={{ 
      display: 'flex', 
      justifyContent: 'center',
      marginLeft: '-2%', 
      marginTop: '-35px' 
    }}>
      <div style={{ 
        marginBottom: '0px',  
        width: '100%',  
        background: 'white', 
        height: '40px',  
        marginLeft: '30px', 
        display: 'flex', 
        alignItems: 'center', 
        padding: '20px 0px',
      }}>
        <label style={{ 
          display: 'flex',
          alignItems: 'center',
          fontFamily: "'montserrat', sans-serif", 
          marginRight: '10px', 
          background: '#F29FFF', 
          padding: '8px 12px', 
          marginLeft: '4%', 
          borderRadius: '5px', 
          color: '#E01FFF',
          cursor: 'pointer',
          transition: 'all 0.2s ease',
          userSelect: 'none'
        }}>
          <div style={{
            position: 'relative',
            display: 'inline-block',
            width: '18px',
            height: '18px',
            marginRight: '8px'
          }}>
            <input
              type="checkbox"
              checked={halfCredit}
              onChange={(e) => setHalfCredit(e.target.checked)}
              style={{
                position: 'absolute',
                opacity: 0,
                cursor: 'pointer',
                height: 0,
                width: 0
              }}
            />
            <span style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '18px',
              width: '18px',
              backgroundColor: halfCredit ? '#E01FFF' : 'white',
              border: '2px solid #E01FFF',
              borderRadius: '3px',
              transition: 'all 0.2s ease',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}>
              {halfCredit && (
                <svg
                  width="12"
                  height="12"
                  viewBox="0 0 12 12"
                  fill="none"
                  style={{
                    transform: 'scale(1.2)',
                  }}
                >
                  <path
                    d="M2 6L5 9L10 3"
                    stroke="white"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              )}
            </span>
          </div>
          Half Credit
        </label>

        <button
          onClick={handleRegrade}
          disabled={isRegrading}
          style={{
            color: isRegrading ? '#F29FFF' : '#E01FFF',
            padding: '8px 20px',
            background: 'transparent',
            fontSize: '18px',
            borderRadius: '5px',
            fontFamily: "'montserrat', sans-serif",
            fontWeight: 'bold',
            border: 'none',
            cursor: isRegrading ? 'not-allowed' : 'pointer',
            transition: 'all 0.2s ease',
            opacity: isRegrading ? 0.7 : 1,
            marginLeft: 'auto',
            marginRight: '4%'
          }}
        >
          {isRegrading ? 'Regrading...' : 'Regrade Assignment'}
        </button>
      </div>
    </div>{showProgressModal && (
  <ProgressModal
    onClose={() => setShowProgressModal(false)}
    progressData={progressData}
    handleRenewAccess={handleRenewAccess}
    handleReplaceSubmission={handleReplaceSubmission}
    handleBackToSubmission={handleBackToSubmission}
  />
)}

        </div>
    );
}

export default TeacherStudentResults;