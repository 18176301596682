import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area
} from 'recharts';

const GradeProgressionChart = ({ grades }) => {
  // Process grades to create chart data
  const chartData = grades
    .sort((a, b) => a.submittedAt.toDate() - b.submittedAt.toDate())
    .map(grade => ({
      name: grade.assignmentName,
      average: grade.score || 0,
      date: new Date(grade.submittedAt.toDate()).toLocaleDateString()
    }));

  const getGradeColors = (grade) => {
    if (grade === undefined || grade === null) return { color: 'grey', background: '#f4f4f4' };
    if (grade < 50) return { color: '#FF0000', background: '#FFCBCB' };
    if (grade < 70) return { color: '#FF4400', background: '#FFC6A8' };
    if (grade < 80) return { color: '#EFAA14', background: '#FFF4DC' };
    if (grade < 90) return { color: '#9ED604', background: '#EDFFC1' };
    if (grade > 99) return { color: '#E01FFF', background: '#F7C7FF' };
    return { color: '#2BB514', background: '#D3FFCC' };
  };

  return (
    <div style={{
      width: 'calc(92% ',
      padding: '0px 4%',
      height: "200px",
      marginTop: '30px',
      marginBottom: '20px',
      position: 'relative'
    }}>
      <ResponsiveContainer>
        <LineChart data={chartData} margin={{ top: 5, right: 0, left: -30, bottom: 5 }}>
          <defs>
            <linearGradient id="colorGradient" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#FFC400" stopOpacity={0.2}/>
              <stop offset="95%" stopColor="#FFC400" stopOpacity={0.05}/>
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 3" stroke="#ededed" />
          <XAxis 
            dataKey="name"
            tick={false}
            stroke="lightgrey"
            height={20}
          />
          <YAxis
            domain={[0, 100]}
            stroke="lightgrey"
            ticks={[10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
            axisLine={true}
          />
          <Tooltip
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                const data = payload[0].payload;
                const gradeStyle = getGradeColors(data.average);
                return (
                  <div style={{
                    padding: '5px 15px',
                    borderRadius: '10px',
                    backgroundColor: 'white',
                    cursor: 'pointer',
                    border: '1px solid #ededed',
                    boxShadow: 'rgba(50, 50, 205, 0.10) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
                  }}>
                    <p style={{ fontWeight: '500', marginBottom: '5px' }}>{data.name}</p>
                    <div style={{ display: 'flex' }}>
                      <p style={{ color: 'grey', marginBottom: '5px' }}>{data.date}</p>
                      <p style={{
                        marginLeft: '20px',
                        color: gradeStyle.color,
                        background: gradeStyle.background,
                        padding: '2px 8px',
                        borderRadius: '5px',
                      }}>
                        {data.average}%
                      </p>
                    </div>
                  </div>
                );
              }
              return null;
            }}
          />
          <Area
            type="monotone"
            dataKey="average"
            stroke="none"
            fillOpacity={1}
            fill="url(#colorGradient)"
          />
          <Line
            type="monotone"
            dataKey="average"
            stroke="#555CFF"
            strokeWidth={2}
            dot={{ r: 2.5, fill: "white", stroke: "blue", strokeWidth: 1.5 }}
            activeDot={{ r: 4, fill: "white", stroke: "blue", strokeWidth: 2 }}
            connectNulls={true}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default GradeProgressionChart;