import React from 'react';
import { Flag, Eye, SquarePlus } from 'lucide-react';

const AddAssignmentsView = ({ 
  folder, 
  assignments, 
  onAddAssignment, 
  getFormatDisplay, 
  formatDate, 
  getGradeColors, 
  flaggedAssignments 
}) => {
  const availableAssignments = assignments.filter(assignment => 
    !folder.assignments.includes(assignment.id)
  );

  return (
    <div style={{ width: 'calc(100% - 200px)', marginLeft: '200px' }}>
      <ul style={{ listStyleType: 'none', padding: 0, width: '100%', marginTop: '180px' }}>
        {availableAssignments.length > 0 ? (
          availableAssignments.map((item) => (
            <li
              key={item.id}
              style={{
                padding: '20px 4%',
                width: '92%',
                borderBottom: '1px solid #EDEDED',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                position: 'relative',
                transition: 'background-color 0.3s',
              }}
            >
              <span
                style={{
                  fontSize: '16px',
                  fontFamily: "'Montserrat', sans-serif",
                  fontWeight: '500',
                  color: 'grey'
                }}
              >
                {item.name}
              </span>
              <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '20px',
                marginLeft: 'auto',
                width: '20%',
              }}>
                {item.average ? (
                  <span style={{ 
                    right: 'calc(4% + 30%)',
                    fontWeight: '500',
                    position: 'absolute',
                    background: getGradeColors(item.average).background,
                    color: getGradeColors(item.average).color,
                    padding: '5px',
                    borderRadius: '5px',
                    width: '40px',
                    textAlign: 'center',
                  }}>
                    {item.average}%
                  </span>
                ) : (
                  <span style={{ 
                    right: 'calc(4% + 30%)',
                    fontWeight: '500',
                    position: 'absolute',
                    background: 'white',
                    color: '#858585',
                    padding: '5px',
                    borderRadius: '5px',
                    width: '40px',
                    textAlign: 'center',
                  }}>
                    -
                  </span>
                )}
                <span
                  style={{
                    fontSize: '16px',
                    color: 'lightgrey',
                    right: 'calc(25%)',
                    fontWeight: '500',
                    position: 'absolute',
                    fontFamily: "'Montserrat', sans-serif",
                  }}
                >
                  {formatDate(item.id)}
                </span>

                {/* Flag and Eye Icons */}
                <div style={{ 
                  display: 'flex',
                  alignItems: 'center',
                  gap: '15px',
                  marginLeft: '10px'
                }}>
                  {flaggedAssignments.has(item.id) && (
                    <Flag size={20} color="red" 
                    style={{position: 'absolute', right: 'calc(4% + 40%)'}}
                    />
                  )}
                  {item.viewable && (
                    <Eye size={20} color="#020CFF"
                    style={{position: 'absolute', right: 'calc(4% + 37%)'}}
                    />
                  )}
                </div>

                {/* Format Display */}
                <div style={{ marginLeft: '-15%', height: '30px', width: '50px', position: 'relative',  }}>
                  {getFormatDisplay(item.type)}
                </div>

                {/* Add Button */}
                <button
                  onClick={() => onAddAssignment(item.id)}
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    padding: '5px',
                    position: 'absolute',
                    right: '4%',
                  }}
                >
                  <SquarePlus size={24} color="#29DB0B" />
                </button>
              </div>
            </li>
          ))
        ) : (
          <li style={{
            padding: '20px 4%',
            color: '#666',
            fontFamily: "'Montserrat', sans-serif",
            fontSize: '18px',
            marginTop: '-20px',
            fontWeight: '500'
          }}>
            No available assignments to add.
          </li>
        )}
      </ul>
    </div>
  );
};

export default AddAssignmentsView;