import React from 'react';
import { ArrowLeft, Folder, Trash2 } from 'lucide-react';

const FolderHeader = ({ 
  folder,
  onBack,
  onAddAssignments,
  onDeleteFolder,
  isAddingAssignments = false 
}) => {
  const handleDeleteClick = () => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete the folder "${folder.name}"? This action cannot be undone.`
    );
    
    if (confirmDelete) {
      onDeleteFolder(folder.id);
    }
  };
  const getLighterColor = (color) => {
    if (!color) return 'rgba(128, 128, 128, 0.6)'; // default light grey
    
    // If color is already in rgba format
    if (color.startsWith('rgba')) {
      return color.replace(/[\d.]+\)$/g, '0.6)');
    }
    
    // If color is in hex format
    if (color.startsWith('#')) {
      return color + '10'; // Add 60% opacity in hex
    }
    
    // If color is a named color, convert to rgba
    return `rgba(${color === 'grey' ? '128, 128, 128' : '128, 128, 128'}, 0.6)`;
  };



  return (
    <div
      style={{
        width: 'calc(100% - 200px)',
        height: '70px',
        position: 'fixed',
        top: '107px',
        left: '200px',
        zIndex: '2',
        background: getLighterColor(folder?.color?.text),
        backdropFilter: 'blur(5px)',
        borderBottom: '1px solid #EDEDED',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 4%'
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center', gap: '15px', marginTop: '10px' }}>
        <button
          onClick={onBack}
          style={{
            background: 'none',
            position: 'absolute',
            left: '1%',
            border: 'none',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            padding: '0'
          }}
        >
          <ArrowLeft size={20} color={folder?.color?.text || 'grey'} />
        </button>

        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginLeft: '-8%' }}>
          <Folder size={24} color={folder?.color?.text || 'grey'} />
          <span
            style={{
              fontSize: '20px',
              fontFamily: "'Montserrat', sans-serif",
              fontWeight: '600',
              color: folder?.color?.text || 'grey'
            }}
          >
            {folder?.name}
          </span>
        </div>
      </div>

      <div style={{ 
        position: 'absolute', 
        right: '12%', 
        display: 'flex', 
        gap: '10px',
        alignItems: 'center' 
      }}>
        <button
          onClick={onAddAssignments}
          style={{
            backgroundColor: 'white',
            color: isAddingAssignments ? 'grey' : folder?.color?.text,
            border: '1px solid lightgrey',
            padding: '8px 16px',
            borderRadius: '5px',
            cursor: 'pointer',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontFamily: "'Montserrat', sans-serif",
            fontSize: '14px',
            marginTop: '10px',
            marginBottom: '-10px'
          }}
        >
          {isAddingAssignments ? 'done' : 'Add Assignments'}
        </button>
        
        <button
          onClick={handleDeleteClick}
          style={{
            backgroundColor: 'white',
            color: '#FF3B3B',
            border: '1px solid lightgrey',
            padding: '8px 16px',
            borderRadius: '5px',
            cursor: 'pointer',
            opacity: '.6',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            fontFamily: "'Montserrat', sans-serif",
            fontSize: '14px',
            marginTop: '10px',
            marginBottom: '-10px'
          }}
        >
          <Trash2 size={20} />
        </button>
      </div>
    </div>
  );
};

export default FolderHeader;