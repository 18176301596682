import React from 'react';
import { Folder, FolderPlus } from 'lucide-react';

const Folders = ({
  classId,
  folders,
  assignments,
  pastelColors,
  onFoldersUpdated,
  openFolderModal,
  onCreateFolder
}) => {
  const getFolderAssignmentCount = (folder) => {
    return folder.assignments ? folder.assignments.length : 0;
  };

  return (
    <div style={{ 
      marginTop: '150px', 
      marginLeft: '200px', 
      padding: '0 4%', 
      width: 'calc(92% - 200px)',
    }}>
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '20px',
        width: '100%', 
      }}>
        {/* Create Folder Button */}
        <div 
          onClick={onCreateFolder}
          style={{
            height: '60px',
            backgroundColor: '#fff',
            borderRadius: '10px',    border: '1px solid #ededed',
            boxShadow: 'rgba(50, 50, 205, 0.05) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
        
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            transition: 'all 0.2s ease',
            padding: '20px',
            '&:hover': {
              backgroundColor: '#f8f8f8',
              transform: 'translateY(-2px)'
            }
          }}
        >
          <FolderPlus size={40} color="#29DB0B" />
          <span style={{
            marginTop: '0px',
            fontSize: '25px',marginLeft: '20px',
            fontFamily: "'Montserrat', sans-serif",
            color: '#29DB0B',
            fontWeight: '500'
          }}>
            Create Folder
          </span>
        </div>

        {/* Folder Cards */}
        {folders.map((folder) => (
          <div
            key={folder.id}
            onClick={() => openFolderModal(folder)}
            style={{
              height: '60px',
              backgroundColor: '#fff',
              borderRadius: '10px',    border: '1px solid #ededed',
              boxShadow: 'rgba(50, 50, 205, 0.05) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
          
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              transition: 'all 0.2s ease',
              padding: '20px',
              '&:hover': {
                backgroundColor: '#f8f8f8',
                transform: 'translateY(-2px)'
              }
            }}
          >
            <Folder 
              size={40} 
              color={folder.color?.text || 'grey'}
            />
            <div style={{marginLeft: '20px'}}>
            <h1 style={{
              marginTop: '10px',
              fontSize: '20px',
              fontFamily: "'Montserrat', sans-serif",
              color: folder.color?.text || 'grey',
              fontWeight: '500',
              textAlign: 'left',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              width: '100%'
            }}>
              {folder.name}
            </h1>
            <h1 style={{
              marginTop: '-10px',
              fontSize: '16px',
              fontFamily: "'Montserrat', sans-serif",
              color: '#666',
              fontWeight: '400'
            }}>
              {getFolderAssignmentCount(folder)} {getFolderAssignmentCount(folder) === 1 ? 'Assignment' : 'Assignments'}
            </h1>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Folders;