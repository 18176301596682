import React, { useState } from 'react';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../../Universal/firebase';
import { FolderPlus, SquareX } from 'lucide-react';

const pastelColors = [
  { bg: '#FFF2A9', text: '#FFD000' },
  { bg: '#FFB411', text: '#EA6200' },
  { bg: '#F7C7FF', text: '#B513D2' },
  { bg: '#FFBCBC', text: '#C10E0E' },
  { bg: '#84FDFF', text: '#00D3D7' },
  { bg: '#DAB5FF', text: '#7C00F8' },
  { bg: '#9EADFF', text: '#020CFF' },
  { bg: '#C1FFC7', text: '#48E758' },
];

const MAX_LENGTH = 20;

const CreateFolder = ({ classId, onFolderCreated, onClose }) => {
  const [newFolderName, setNewFolderName] = useState('');
  const [selectedColor, setSelectedColor] = useState(pastelColors[0]);

  const handleCreateFolder = async () => {
    if (newFolderName.trim() === '') return;

    try {
      const classDocRef = doc(db, 'classes', classId);
      const newFolder = {
        name: newFolderName,
        color: selectedColor,
        assignments: [],
        id: Date.now().toString()
      };

      await updateDoc(classDocRef, {
        folders: arrayUnion(newFolder),
      });

      onFolderCreated();
      onClose();
      setNewFolderName('');
      setSelectedColor(pastelColors[0]);
    } catch (error) {
      console.error('Error creating folder:', error);
    }
  };

  return (
    <div style={{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backdropFilter: 'blur(5px)',
      background: 'rgba(255,255,255,0.8)',
      zIndex: 1
    }}>
      <div style={{
        position: 'fixed',
        top: '50%',
        left: '50%',
        borderRadius: '20px',
        backdropFilter: 'blur(5px)',
        transform: 'translate(-50%, -50%)',
        width: '500px',
        backgroundColor: 'rgb(255,255,255,.001)',
        border: '1px solid lightgrey',
        fontFamily: 'Arial, sans-serif',
        zIndex: 1
      }}>
        <div style={{
          padding: '40px 40px 20px 40px',
          textAlign: 'left',
          fontWeight: '600',
          fontFamily: '"montserrat", sans-serif',
        }}>
          <div style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '40px'
          }}>
            <FolderPlus size={35} style={{ marginRight: '10px', color: 'black' }} />
            <h1 style={{
              fontSize: '30px',
              fontWeight: '500',
              margin: 0,
              color: 'black'
            }}>
              Create New Folder
            </h1>
          </div>

          <div style={{ position: 'relative', marginBottom: '20px', marginTop: '30px' }}>
            <input
              type="text"
              value={newFolderName}
              onChange={(e) => setNewFolderName(e.target.value.slice(0, MAX_LENGTH))}
              placeholder="Folder Name"
              style={{
                width: '93%',
                padding: '8px 12px',
                borderRadius: '5px',
                border: '1px solid lightgrey',
                fontSize: '16px',
                fontFamily: '"montserrat", sans-serif',
              }}
            />
            <div style={{
              position: 'absolute',
              right: '20px',
              top: '10px',
              fontSize: '14px',
              color: newFolderName.length === MAX_LENGTH ? '#ff6b6b' : 'lightgrey',
              fontFamily: '"montserrat", sans-serif',
              fontWeight: '500'
            }}>
              {newFolderName.length}/{MAX_LENGTH}
            </div>
          </div>

          <div style={{ marginBottom: '30px', display: 'flex' }}>
            <h2 style={{
              fontSize: '16px',
              marginTop: '5px',
              marginBottom: '40px',
              color: 'grey',
              fontWeight: '500'
            }}>
              Theme:
            </h2>
            <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap', marginLeft: '20px' }}>
              {pastelColors.map((color, index) => (
                <div
                  key={index}
                  onClick={() => setSelectedColor(color)}
                  style={{
                    width: '20px',
                    height: '20px',
                    backgroundColor: color.bg,
                    border: selectedColor === color ? `4px solid ${color.text}` : '4px solid transparent',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    transition: 'transform 0.2s'
                  }}
                />
              ))}
            </div>
          </div>

          <div style={{
            display: 'flex',
            justifyContent: 'center',
          }}>
            <button
              onClick={handleCreateFolder}
              style={{
                width: '200px',
                height: '30px',
                lineHeight: '10px',
                padding: '5px 5px',
                fontWeight: '500',
                fontSize: '16px',
                borderRadius: '5px',
                fontFamily: '"montserrat", sans-serif',
                backgroundColor: 'white',
                border: '1px solid lightgrey',
                color: '#45B434',
                cursor: 'pointer',
                transition: '.3s',
                marginRight: '15px'
              }}
            >
              Create
            </button>
            <button
              onClick={onClose}
              style={{
                width: '200px',
                height: '30px',
                lineHeight: '10px',
                padding: '5px 5px',
                fontWeight: '500',
                fontSize: '16px',
                borderRadius: '5px',
                fontFamily: '"montserrat", sans-serif',
                backgroundColor: 'white',
                border: '1px solid lightgrey',
                color: 'grey',
                cursor: 'pointer',
                transition: '.3s'
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateFolder;