import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../Universal/firebase';


const FooterAuth = () => {

    const foot = {  fontFamily: "'montserrat', sans-serif", color: 'darkgrey', fontSize: '14', marginTop: '10px', textDecoration: 'none'}

return (
    <div style={
        {position: 'relative',width: '100%',
        height: '300px',
        borderTop: '1px solid lightgrey',
        backgroundColor: 'white' 
        }}>

<div style={{width: '100%', height: '200px', backgroundColor: 'white', marginLeft: 'auto',
marginRight: 'auto',display: 'flex', flexDirection: 'row', marginTop: '50px'}}>

<div style={{width: '900px', marginLeft: 'auto', marginRight: 'auto', display: 'flex', flexDirection: 'row'}}>
  <div style={{width: '50%', backgroundColor: 'white', height: '200px', marginLeft: 'auto', flexDirection: 'row',display: 'flex'}}>

<div style={{width: '50%', backgroundColor: 'white', height: '200px', flexDirection: 'column',display: 'flex',}}>
<h4 style={{fontWeight:'bold', fontSize: '16px', marginBottom: '0px'}}>Amoeba</h4>
<Link style={{...foot}}>About us</Link>
  <Link style={{...foot}}>Tutorials</Link>
  <Link style={{...foot}}>Help</Link>
  <Link style={{...foot}}>Feedback</Link>
  
</div>
<div style={{width: '50%', backgroundColor: ' white', height: '200px', flexDirection: 'column',display: 'flex',}}>
<h4 style={{fontWeight:'bold', fontSize: '16px', marginBottom: '0px'}}>Legal</h4>
<Link style={{...foot}}>Privacy Policy</Link>
<Link to="/termsofservice"style={{...foot}}>Terms of Service</Link>
</div>

  </div>
  
<div style={{width: '25%', backgroundColor: 'white', height: '200px', marginRight: 'auto', flexDirection: 'column',display: 'flex'}}>
<div style={{height: '50%' ,backgroundColor: 'white'}}>
  <img src='
/Footer.svg'
style={{width: '200px', marginTop: '7px', marginLeft: 'auto',  border: '1px solid #ededed',   boxShadow: 'rgba(50, 50, 205, 0.05) 0px 2px 5px 0px, rgba(0, 0, 0, 0.05) 0px 1px 1px 0px',
  marginRight: 'auto', padding: '10px 30px', borderRadius: '10px'}}/>

</div>
<div style={{height: '20%',marginTop: '45px', backgroundColor: 'white', flexDirection: 'row',display: 'flex'}}>
  <Link style={{width: '53px', backgroundColor: 'transparent', cursor: 'pointer', borderColor: 'transparent'}}>
    <img src='https://cdn3.iconfinder.com/data/icons/picons-social/57/06-facebook-512.png'style={{width: '30px', backgroundColor: 'transparent', cursor: 'pointer', borderColor: 'transparent'}}/>
  </Link>
  <Link style={{width: '53px', marginLeft: '23px',backgroundColor: 'transparent', cursor: 'pointer', borderColor: 'transparent'}}>
    <img src='https://cdn4.iconfinder.com/data/icons/social-media-black-white-2/600/Instagram_glyph_svg-512.png'style={{width: '30px', backgroundColor: 'transparent', cursor: 'pointer', borderColor: 'transparent'}}/>
  </Link>
  <Link style={{width: '53px',marginLeft: '23px', backgroundColor: 'transparent', cursor: 'pointer', borderColor: 'transparent'}}>
    <img src='https://www.svgrepo.com/show/333611/tiktok.svg'style={{width: '30px', backgroundColor: 'transparent', cursor: 'pointer', borderColor: 'transparent'}}/>
  </Link>
  <Link style={{width: '53px',marginLeft: '23px', backgroundColor: 'transparent', cursor: 'pointer', borderColor: 'transparent'}}>
    <img src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRMzArllT3OlZ_oaqOTCCigwY0CWVu50szLpSTTaoOlSg&s'style={{width: '30px', backgroundColor: 'transparent', cursor: 'pointer', borderColor: 'transparent'}}/>
  </Link>
  <Link style={{width: '53px', marginLeft: '23px', backgroundColor: 'transparent', cursor: 'pointer', borderColor: 'transparent'}}>
    <img src='https://cdn-icons-png.flaticon.com/512/717/717426.png' style={{width: '30px', backgroundColor: 'transparent', cursor: 'pointer', borderColor: 'transparent'}}/>
  </Link>
</div>

</div>


</div>
</div>
</div>
);
};

export default FooterAuth;
