import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { CheckCircle } from 'lucide-react';

const PageNotFound = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showContent, setShowContent] = useState(false);
  
  // Use sessionStorage to prevent refresh loops
  const hasRefreshed = sessionStorage.getItem('hasRefreshed');
  const isSuccess = location.state?.success || location.pathname === '/studenthome';

  useEffect(() => {
    // If we haven't refreshed yet and we're on the success page
    if (!hasRefreshed && isSuccess) {
      // Set the flag immediately to prevent multiple refreshes
      sessionStorage.setItem('hasRefreshed', 'true');
      
      // Wait a brief moment before refreshing
      const refreshTimeout = setTimeout(() => {
        window.location.reload();
      }, 500);

      return () => clearTimeout(refreshTimeout);
    } else {
      // If we've already refreshed or it's not the success page,
      // show the content after a delay
      const showTimeout = setTimeout(() => {
        setShowContent(true);
      }, 500);

      return () => clearTimeout(showTimeout);
    }
  }, [hasRefreshed, isSuccess]);

  // Clear the refresh flag when leaving the page
  useEffect(() => {
    return () => {
      sessionStorage.removeItem('hasRefreshed');
    };
  }, []);

  const content = isSuccess ? {
    title: "Class Added Successfully",
    message: "Class has been added to your roster - refresh to see changes",
    buttonText: "Refresh",
    isSuccess: true
  } : {
    title: "404 - Page Not Found",
    message: "The page you are looking for does not exist. If you require assistance, please contact us.",
    contactEmail: "rodrigo.squarescore@gmail.com",
    buttonText: "Return Home",
    isSuccess: false
  };

  const handleButtonClick = () => {
    if (isSuccess) {
      window.location.reload();
    } else {
      navigate('/');
    }
  };

  // If content shouldn't be shown yet, show nothing
  if (!showContent) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        background: 'white',
        paddingTop: '150px'
      }}/>
    );
  }

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      minHeight: '100vh',
      background: 'white',
      paddingTop: '150px',
      opacity: showContent ? 1 : 0,
      transition: 'opacity 0.3s ease-in'
    }}>
      <div style={{
        width: '580px',
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        padding: '40px',
        borderRadius: '20px',
        boxShadow: '1px 1px 5px 1px rgb(0,0,155,.1)',
        background: 'white'
      }}>
        {content.isSuccess && (
          <CheckCircle 
            style={{
              margin: '0 auto 20px auto',
              color: '#2BB514',
              background: '#AEF2A3',
              padding: '10px',
              borderRadius: '10px'
            }}
            size={48}
          />
        )}
        
        <h1 style={{
          fontFamily: '"montserrat", sans-serif',
          fontSize: '40px',
          marginBottom: '30px',
          fontWeight: '500',
          color: content.isSuccess ? '#2BB514' : 'black'
        }}>
          {content.title}
        </h1>
        
        <p style={{
          fontFamily: '"montserrat", sans-serif',
          fontSize: '20px',
          fontWeight: '500',
          marginBottom: '40px',
          color: 'grey',
          lineHeight: '1.6'
        }}>
          {content.message}
          {!content.isSuccess && (
            <>
              <br />
              <span style={{color: '#666'}}>
                Email: {content.contactEmail}
              </span>
            </>
          )}
        </p>
        
        <button
          onClick={handleButtonClick}
          style={{
            padding: '10px 30px',
            height: '50px',
            border: '1px solid #ddd',
            borderRadius: '10px',
            fontFamily: '"montserrat", sans-serif',
            fontSize: '20px',
            fontWeight: '500',
            backgroundColor: 'white',
            color: content.isSuccess ? '#2BB514' : 'grey',
            cursor: 'pointer',
            transition: 'all 0.3s ease',
            boxShadow: '1px 1px 5px 1px rgb(0,0,155,.1)'
          }}
          onMouseEnter={(e) => {
            e.target.style.boxShadow = '1px 1px 10px 1px rgb(0,0,155,.1)';
          }}
          onMouseLeave={(e) => {
            e.target.style.boxShadow = '1px 1px 5px 1px rgb(0,0,155,.1)';
          }}
        >
          {content.buttonText}
        </button>
      </div>
    </div>
  );
};

export default PageNotFound;