// QuestionBankAMCQ.js

import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../Universal/firebase';
import React, { useState, useEffect } from 'react';
import {
  Pencil,
  PencilOff,
  Check,
  Square,
  SquareCheck,
  SquareX,
  DeleteIcon,
  ChevronDown,
  ChevronUp,
  ArrowRight,
  MessageSquareMore,
  Search,
  BarChart,
  Target,
  Users,
} from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
const QuestionBankHeader = ({ questionsCount, averageScore, onSearchChange, searchTerm, getGradeColors }) => (
  <div style={{ 
    width: '100%',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    background: '#FCFCFF',
    borderBottom: "1px solid #ededed",
   marginTop: '-100px'
  }}>
    <div style={{ 
      marginLeft: '4%', 
      width: '460px', 
      display: 'flex', 
      marginTop: '5px',
      position: 'relative',
      zIndex: 1,
      alignItems: 'center'
    }}>
      <h1 style={{  
        fontWeight: '500',
        fontSize: '16px',
        margin: '0',
        display: 'flex',
        alignItems: 'center',
        color: '#555CFF',
        height: '100%'
      }}>
        {questionsCount} Questions 
      </h1>

      
    </div>

    <div style={{
      marginLeft: 'auto',
      marginRight: '4%',
      position: 'relative',
      width: '300px'
    }}>
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => onSearchChange(e.target.value)}
        placeholder="Search questions..."
        style={{
          width: '254px',
          padding: '8px 12px',
          paddingLeft: '35px',
          borderRadius: '5px',
          border: '1px solid lightgrey',
          fontSize: '14px',
          fontFamily: "'montserrat', sans-serif",
          outline: 'none'
        }}
      />
      <Search
        size={18} 
        style={{
          position: 'absolute',
          left: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          color: 'grey'
        }}
      />
    </div>
    <div style={{
        fontSize: '16px',
        padding: '5px',
        marginRight: '4%',
        textAlign: 'center',
        width: "40px",
        borderRadius: '5px',
        background: averageScore ? getGradeColors(averageScore).background : 'white',
        color: averageScore ? getGradeColors(averageScore).color : '#858585',
      }}> 
        {averageScore !== null ? averageScore : '-'}%
      </div>
  </div>
);

const ChoiceStats = ({ question, choice, questionStats, isCorrect, style }) => {
  const stats = questionStats[question.question];
  if (!stats || !stats.choiceDistribution) return null;

  const distribution = stats.choiceDistribution[choice];
  const usersSelected = distribution ? 1 : 0; // Since each user choice is recorded as a single entry

  return (
    <div style={{
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      height: '100%',
      padding: '0 10px',
      gap: '4px'
    }}>
      {usersSelected > 0 && (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          background: 'transparent' ,
          padding: '3px 8px',
          borderRadius: '4px',
          fontSize: '13px',
          color: style.color,
          fontWeight: '500',
          height: '24px'
        }}>
          <Users size={20} />
          {usersSelected}
        </div>
      )}
    </div>
  );
};
const QuestionBankAMCQ = ({
  editedQuestions,
  setEditedQuestions,
  assignmentId,
  onClose,
  questionStats,
  totalSubmissions
}) => {
  const [editingQuestionIndex, setEditingQuestionIndex] = useState(null);
  const [showChoices, setShowChoices] = useState({});
  const [hoveredChoice, setHoveredChoice] = useState(null);
  const [selectedExplanations, setSelectedExplanations] = useState({}); // Tracks selected choice per question for explanations
  const [searchTerm, setSearchTerm] = useState('');
  // Define choice styles
  const choiceStyles = {
    a: { background: '#C7CFFF', color: '#020CFF' },
    b: { background: '#F5B6FF', color: '#E441FF' },
    c: { background: '#AEF2A3', color: '#2BB514' },
    d: { background: '#FFEAAF', color: '#FFAE00' },
    e: { background: '#CAFFF4', color: '#00F1C2' },
    f: { background: '#C2FBFF', color: '#CC0000' },
    g: { background: '#E3BFFF', color: '#8364FF' },
    h: { background: '#9E9E9E', color: '#000000' },
  };

  const difficultyStyles = {
    Easy: { background: '#FDFFC1', color: '#FFD13B' },
    Medium: { background: '#FFE2AC', color: '#FFAE00' },
    Hard: { background: '#FFB764', color: '#E07800' },
  };

  const difficultyOrder = ['Easy', 'Medium', 'Hard'];

  // Handle difficulty click to cycle through levels
  const handleDifficultyClick = (questionIndex) => {
    if (editingQuestionIndex === questionIndex) {
      const currentIndex = difficultyOrder.indexOf(
        editedQuestions[questionIndex].difficulty
      );
      const nextIndex = (currentIndex + 1) % difficultyOrder.length;
      const nextDifficulty = difficultyOrder[nextIndex];
      handleDifficultyChange(questionIndex, nextDifficulty);
    }
  };

  // Get choice style based on choice key
  const getChoiceStyle = (choice) => {
    return (
      choiceStyles[choice.toLowerCase()] || { background: 'white', color: '#000000' }
    );
  };

  const getDifficultyDisplay = (difficulty) => {
    return difficulty === 'Medium' ? 'Med' : difficulty;
  };

  // Toggle editing mode for a question
  const handleEditQuestion = (index) => {
    setEditingQuestionIndex(index === editingQuestionIndex ? null : index);
  };

  // Handle changes to question text
  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = [...editedQuestions];
    updatedQuestions[index][field] = value;
    setEditedQuestions(updatedQuestions);
  };

  // Handle changes to a specific choice
  const handleChoiceChange = (questionIndex, choice, value) => {
    const updatedQuestions = [...editedQuestions];
    updatedQuestions[questionIndex][choice] = value;
    setEditedQuestions(updatedQuestions);
  };

  // Handle changes to explanations for choices
  const handleExplanationChange = (questionIndex, choice, value) => {
    const updatedQuestions = [...editedQuestions];
    const explanationKey = `explanation_${choice.toLowerCase()}`;
    updatedQuestions[questionIndex][explanationKey] = value;
    setEditedQuestions(updatedQuestions);
  };

  // Handle changes to the correct answer
  const handleCorrectAnswerChange = (questionIndex, newCorrectChoice) => {
    const updatedQuestions = [...editedQuestions];
    updatedQuestions[questionIndex].correct = newCorrectChoice;
    setEditedQuestions(updatedQuestions);
  };

  // Handle changes to difficulty level
  const handleDifficultyChange = (questionIndex, newDifficulty) => {
    const updatedQuestions = [...editedQuestions];
    updatedQuestions[questionIndex].difficulty = newDifficulty;
    setEditedQuestions(updatedQuestions);
  };

  // Handle deletion of a choice
  const handleDeleteChoice = (questionIndex, choiceToDelete) => {
    if (window.confirm('Are you sure you want to delete this choice?')) {
      const updatedQuestions = [...editedQuestions];
      const question = updatedQuestions[questionIndex];

      // Remove the choice and its explanation
      delete question[choiceToDelete];
      delete question[`explanation_${choiceToDelete.toLowerCase()}`];

      // If the deleted choice was the correct answer, assign a new correct answer
      if (question.correct.toLowerCase() === choiceToDelete.toLowerCase()) {
        const remainingChoices = Object.keys(question).filter((key) =>
          key.match(/^[a-z]$/)
        );
        if (remainingChoices.length > 0) {
          question.correct = remainingChoices[0];
        } else {
          question.correct = '';
        }
      }

      setEditedQuestions(updatedQuestions);
      saveChanges();
    }
  };
  const questionsWithIds = editedQuestions.map((q, index) => ({
    ...q,
    id: index
  }));

  // Calculate average score from questions
  const calculateAverageScore = () => {
    const scores = editedQuestions
      .map(q => q.averageScore)
      .filter(score => score !== undefined && score !== null);
    
    if (scores.length === 0) return null;
    
    const average = scores.reduce((a, b) => a + b, 0) / scores.length;
    return Math.round(average);
  };

  // Handle search input changes
  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  // Filter questions based on search term
  const filteredQuestions = questionsWithIds.filter(question =>
    question.question.toLowerCase().includes(searchTerm.toLowerCase())
  );
  // Toggle visibility of choices
  const handleToggleChoices = (index) => {
    setShowChoices((prev) => ({ ...prev, [index]: !prev[index] }));
    // Reset selected explanation when toggling choices
    setSelectedExplanations((prev) => ({ ...prev, [index]: null }));
  };

  // Toggle selected explanation in non-edit mode
  const handleSelectExplanation = (questionIndex, choiceKey) => {
    setSelectedExplanations((prev) => ({
      ...prev,
      [questionIndex]: prev[questionIndex] === choiceKey ? null : choiceKey,
    }));
  };
  // Filter questions based on search term

  // Save changes to Firebase
  const saveChanges = async () => {
    try {
      const assignmentRef = doc(db, 'assignments', assignmentId);
      await updateDoc(assignmentRef, { questions: editedQuestions });
      console.log('Changes saved successfully');
    } catch (error) {
      console.error('Error saving changes:', error);
    }
  };
  const getSuccessRate = (question) => {
    const stats = questionStats[question.question];
    if (!stats || stats.totalAttempts === 0) {
      return null; // Will display as "-"
    }
    return stats.percentageCorrect || 0; // Will display as "0" if incorrect
  };
  useEffect(() => {
    if (editingQuestionIndex === null) {
      saveChanges();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editingQuestionIndex]);

  // Render choices
  const renderChoice = (question, questionIndex, choiceKey) => {
    const isSelected = selectedExplanations[questionIndex] === choiceKey;
    const isCorrect = question.correct.toLowerCase() === choiceKey.toLowerCase();
    const style = getChoiceStyle(choiceKey);
    const explanationKey = `explanation_${choiceKey.toLowerCase()}`;
    const explanation = question[explanationKey];

    return (
      <div
        key={choiceKey}
        style={{
          width: '100%',
          marginLeft: '-40px',
          display: 'flex',
          alignItems: 'flex-start',
          position: 'relative',
          marginBottom: '10px',
        }}
      >
        <div
          style={{
            marginRight: '10px',
            marginTop: '5px',
            color: isCorrect ? '#20BF00' : 'white',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          {isCorrect ? <Check size={20} /> : <SquareX size={20} />}
          {editingQuestionIndex === questionIndex && (
            <div
              style={{
                cursor: 'pointer',
                marginBottom: '5px',
                marginTop: '-20px',
              }}
              onClick={() => handleCorrectAnswerChange(questionIndex, choiceKey)}
            >
              {isCorrect ? (
                <SquareCheck size={24} color={'transparent'} />
              ) : (
                <Square size={24} color={'lightgrey'} />
              )}
            </div>
          )}
        </div>

        <div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
          {editingQuestionIndex === questionIndex ? (
            <textarea
              value={question[choiceKey]}
              onChange={(e) =>
                handleChoiceChange(questionIndex, choiceKey, e.target.value)
              }
              style={{
                width: '100%',
                fontWeight: '500',
                fontSize: '16px',
                border: 'none',
                outline: 'none',
                resize: 'vertical',
                background: style.background,
                color: style.color,
                borderLeft: `4px solid ${style.color}`,
                padding: '10px',
                borderRadius: '3px',
              }}
              rows="1"
              onInput={(e) => {
                e.target.style.height = 'auto';
                e.target.style.height = e.target.scrollHeight + 'px';
              }}
            />
          ) : (
            <div style={{ display: 'flex', width: '100%', height: '40px', alignItems: 'center' }}>
              <p
                style={{
                  flex: 1,
                  fontWeight: '500',
                  fontSize: '16px',
                  padding: '0px 20px',
                  background: style.background,
                  color: style.color,
                  borderLeft: `4px solid ${style.color}`,
                  margin: '5px 0px',
                  cursor: 'pointer',
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center'
                }}
                onClick={() => {
                  if (editingQuestionIndex === null) {
                    handleSelectExplanation(questionIndex, choiceKey);
                  }
                }}
              >
                {question[choiceKey]}
              </p>
              <ChoiceStats 
                question={question}
                choice={choiceKey}
                questionStats={questionStats}
                isCorrect={isCorrect}
                style={style}
              />
            </div>
          )}
        </div>

        {editingQuestionIndex === questionIndex && (
          <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
            <div
              style={{
                cursor: 'pointer',
              }}
              onClick={() => handleDeleteChoice(questionIndex, choiceKey)}
            >
              <SquareX size={24} color={'red'} />
            </div>
          </div>
        )}
      </div>
    );
  };
   const getGradeColors = (grade) => {
    if (grade === undefined || grade === null ) return { color: '#858585', background: 'white' };
    if (grade < 50) return { color: '#FF0000', background: '#FFCBCB' };
    if (grade < 70) return { color: '#FF4400', background: '#FFC6A8' };
    if (grade < 80) return { color: '#EFAA14', background: '#FFF4DC' };
    if (grade < 90) return { color: '#9ED604', background: '#EDFFC1' };
    if (grade > 99) return { color: '#E01FFF', background: '#F7C7FF' };
    return { color: '#2BB514', background: '#D3FFCC' };
  };
  return (
    <div
      style={{
        zIndex: '10',
        marginTop: '-100px',
        width: '100%',
        position: 'relative',
      }}
    >
      <QuestionBankHeader
        questionsCount={questionsWithIds.length}
        averageScore={calculateAverageScore()}
        onSearchChange={handleSearchChange}
        searchTerm={searchTerm}
        getGradeColors={getGradeColors}
      />
      {filteredQuestions.map((question, questionIndex) => {
        const successRate = getSuccessRate(question);
        
        return (
        <div
          key={questionIndex}
          style={{
            padding: '20px 0px',
            marginBottom: '10px',
            marginTop: '10px',
            borderBottom: '1px solid #ededed',
          }}
        >
          {/* Question Header */}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'relative',
              marginLeft: '4%',
              marginBottom: '10px',
            }}
          >
            {/* Difficulty Label */}
            <div
              onClick={() => handleDifficultyClick(questionIndex)}
              style={{
                width: '40px',
                fontSize: '12px',
                fontWeight: '500',
                height: '25px',
                lineHeight: '25px',
                color: difficultyStyles[question.difficulty]?.color,
                textAlign: 'center',
                background: difficultyStyles[question.difficulty]?.background,
                border: `2px ${
                  editingQuestionIndex === questionIndex ? 'dashed' : 'solid'
                } ${difficultyStyles[question.difficulty]?.color}`,
                borderRadius: '5px',
                cursor:
                  editingQuestionIndex === questionIndex ? 'pointer' : 'default',
                transition: 'all 0.2s',
                marginRight: '10px',
              }}
              >
              {getDifficultyDisplay(question.difficulty)}
            </div>

            {/* Question Text or Editable Textarea */}
            {editingQuestionIndex === questionIndex ? (
              <textarea
                value={question.question}
                onChange={(e) =>
                  handleQuestionChange(questionIndex, 'question', e.target.value)
                }
                style={{
                  flex: '1',
                  fontSize: '20px',
                  fontWeight: '600',
                  border: 'none',
                  outline: 'none',
                  resize: 'vertical',
                  minHeight: '1em',
                  overflow: 'hidden',
                  marginRight: '10px',
                }}
                rows="1"
                onInput={(e) => {
                  e.target.style.height = 'auto';
                  e.target.style.height = e.target.scrollHeight + 'px';
                }}
              />
            ) : (
            
              <p
                style={{
                  flex: '1',
                  fontSize: '20px',
                  fontWeight: '600',
                  margin: '0 10px 0 0',
                }}
              >
                {question.question}
              </p>
             
            )}

            {/* Action Buttons */}
            <div style={{ display: 'flex', alignItems: 'center', marginRight: '4%' }}>
              {/* Toggle Choices Button */}
              <button
                onClick={() => handleToggleChoices(questionIndex)}
                style={{
                  background: 'transparent',
                  border: 'none',
                  cursor: 'pointer',
                  marginRight: '10px',
                }}
              >
                {showChoices[questionIndex] ? (
                  <ChevronUp size={24} color="#757575" strokeWidth={2} />
                ) : (
                  <ChevronDown size={24} color="#757575" strokeWidth={2} />
                )}
              </button>

              {/* Edit Button */}
              <button
                onClick={() => handleEditQuestion(questionIndex)}
                style={{
                  cursor: 'pointer',
                  background: 'white',
                  marginRight: '20px',
                  borderRadius: '10px',
                  border: 'none',
                  padding: '5px 8px',
                }}
              >
                {editingQuestionIndex === questionIndex ? (
                  <PencilOff size={24} color="#757575" strokeWidth={2} />
                ) : (
                  <Pencil strokeWidth={2} size={24} color="#757575" />
                )}
              </button>

              {/* Progress Indicator (Placeholder) */}
              <span style={{ marginRight: '10px', 

background:  getGradeColors(successRate).background ,
              color: getGradeColors(successRate).color ,
              fontSize: '16px', 
              padding: '5px',
              textAlign: 'center',
              width: "40px",

              borderRadius: '5px',
               }}>      {successRate !== null ? `${successRate}%` : '-'}</span>
           
            </div>
          </div>

          {/* Choices Section */}
          {(showChoices[questionIndex] || editingQuestionIndex === questionIndex) && (
            <div
              style={{
                width: '92%',
                marginLeft: '4%',
                marginTop: '10px',
                padding: '10px',
                borderRadius: '5px',
              }}
            >
              {/* Render Choices */}
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {Object.keys(question)
                  .filter((key) => key.match(/^[a-z]$/))
                  .map((choice) => renderChoice(question, questionIndex, choice))}
              </div>

              {/* Common Explanation Area for Non-Edit Mode */}
              {editingQuestionIndex !== questionIndex && selectedExplanations[questionIndex] && (
                <div
                  style={{
                    marginTop: '30px',
                    width: '92%',
                    marginLeft: '-10px',
                    marginBottom: '30px',
                  }}
                >
                  <div
                    style={{
                      alignItems: 'center',
                      marginBottom: '10px',
                      fontWeight: '500',
                      display: 'flex',
                    }}
                  >
                    <MessageSquareMore size={24} style={{ marginRight: '10px' }} />
                    <div
                      style={{
                        margin: 0,
                        fontWeight: '600',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      Explanation
                      {/* Colored Square */}
                      <div
                        style={{
                          background:
                            choiceStyles[selectedExplanations[questionIndex].toLowerCase()]
                              ?.background || 'blue',
                          height: '10px',
                          width: '10px',
                          borderRadius: '3px',
                          marginTop: '5px',
                          marginLeft: '10px',
                        }}
                      />
                    </div>
                  </div>
                  <p style={{ margin: 0 }}>
                    {question[`explanation_${selectedExplanations[questionIndex].toLowerCase()}`] ||
                      'No explanation provided.'}
                  </p>
                </div>
              )}
            </div>
                 )}
                 </div>
               );
             })}
           </div>
         );
       };

export default QuestionBankAMCQ;
