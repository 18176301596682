import React from 'react';

const TabButton = ({ label, count, isActive, onClick }) => {
  return (
    <button
      onClick={onClick}
      style={{
        background: 'none',
        border: 'none',
        fontSize: '14px',
        cursor: 'pointer',
        fontWeight: "600",
        marginTop: '-10px',
        padding: '12px 10px',
        fontFamily: "'Montserrat', sans-serif",
        borderBottom: isActive ? '2px solid #020CFF' : '2px solid transparent',
        color: isActive ? '#020CFF' : 'grey',
        display: 'flex',
        alignItems: 'center',
        gap: '5px'
      }}
    >
      {label}
      {count > 0 && (
        <span style={{
          background: 'red',
          color: 'white',
          padding: '0px 4px',
          borderRadius: '2px',
          marginBottom: '-2px',
          fontSize: '12px'
        }}>
          {count}
        </span>
      )}
    </button>
  );
};

export default TabButton;