import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../../Universal/firebase';
import { useNavigate, useParams } from 'react-router-dom';
import Navbar from '../../../Universal/Navbar';
import { Search, SquareCheck, Eye, EyeOff, SquareMinus, SquareCheck as LucideSquareCheck } from 'lucide-react';
import CopyLinkButton from '../../CopyLinkButtonPink';
import GradeProgressionChart from './GradeProgressionChart';

function TeacherStudentGrades() {
  const [grades, setGrades] = useState([]);
  const [studentName, setStudentName] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [hoveredGradeId, setHoveredGradeId] = useState(null);

  const { classId, studentUid } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchGradesAndStudentName = async () => {
      try {
        // Fetch student data and name
        const studentDoc = await getDoc(doc(db, 'students', studentUid));
        if (studentDoc.exists()) {
          const studentData = studentDoc.data();
          setStudentName(`${studentData.firstName} ${studentData.lastName}`);
          
          // Get class grades from student document
          const classGrades = studentData[`class_${classId}`]?.grades || {};
          
          // Transform grades into array
          const gradesArray = Object.entries(classGrades).map(([assignmentId, gradeData]) => {
            const parts = assignmentId.split('+');
            const format = parts[parts.length - 1]; 
            
            return {
              id: assignmentId,
              assignmentId: assignmentId,
              ...gradeData,
              type: format,
              submittedAt: gradeData.submittedAt,
              percentageScore: gradeData.score
            };
          });

          // Sort by submission date
          const sortedGrades = gradesArray.sort((a, b) => 
            b.submittedAt.toDate() - a.submittedAt.toDate()
          );

          setGrades(sortedGrades);
        }
      } catch (error) {
        console.error("Error fetching grades:", error);
      }
    };

    fetchGradesAndStudentName();
  }, [classId, studentUid]);

  // Helper function to parse the format from the document ID
  const parseFormatFromId = (id) => {
    const parts = id.split('+');
    if (parts.length < 3) return 'UNKNOWN';

    const formatStudentId = parts[2];
    const formatParts = formatStudentId.split('_');
    if (formatParts.length < 1) return 'UNKNOWN';

    const format = formatParts[0];
    const validFormats = ['OE', 'AMCQ', 'MCQ'];
    return validFormats.includes(format) ? format : 'UNKNOWN';
  };

  const navigateToResults = (assignmentId, gradeType) => {
    if (gradeType === 'OE') {
      navigate(`/teacherStudentResults/${assignmentId}/${studentUid}/${classId}`);
    } else if (gradeType === 'AMCQ') {
      navigate(`/teacherStudentResultsAMCQ/${assignmentId}/${studentUid}/${classId}`);
    } else if (gradeType === 'MCQ') {
      navigate(`/teacherStudentResultsMCQ/${assignmentId}/${studentUid}/${classId}`);
    }
  };

  const getLetterGrade = (percentage) => {
    if (percentage >= 90) return 'A';
    if (percentage >= 80) return 'B';
    if (percentage >= 70) return 'C';
    if (percentage >= 60) return 'D';
    return 'F';
  };

  const filteredGrades = grades.filter(grade =>
    grade.assignmentName.toLowerCase().includes(searchTerm.toLowerCase())
  );
  const getGradeColors = (grade) => {
    if (grade === undefined || grade === null) return { color: 'grey', background: '#f4f4f4' };
    if (grade < 50) return { color: '#FF0000', background: '#FFCBCB' };
    if (grade < 70) return { color: '#FF4400', background: '#FFC6A8' };
    if (grade < 80) return { color: '#EFAA14', background: '#FFF4DC' };
    if (grade < 90) return { color: '#9ED604', background: '#EDFFC1' };
    if (grade > 99) return { color: '#E01FFF', background: '#F7C7FF' };
    return { color: '#2BB514', background: '#D3FFCC' };
  };

  return (
    <div style={{
      minHeight: '100vh',
      width: '100%',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      fontFamily: "'Montserrat', sans-serif"
    }}>
      <Navbar
        userType="teacher"
     
      />
      {/* Search Bar */}
    

      {/* Grades List */}
      <div style={{
         width: 'calc(100% - 200px)',
         marginLeft: '200px',
         

      }}>


<div style={{  padding: '0px 4%',   
  height: '130px', position: 'fixed', width: '92%', top: '0px', zIndex: '30', background: 'rgb(255,255,255,.8)',
  backdropFilter: 'blur(5px)', borderBottom: '1px solid lightgrey'
 }}>
          <h1 style={{ fontSize: '30px', fontWeight: '500' }}>  {studentName}</h1>
          <div style={{
    fontSize: '16px',
    padding: '3px 10px',
    height: '20px',
    marginTop: '12px',
    width: '50px',
            textAlign: 'center',
    borderRadius: '5px',
    color: getGradeColors(
      grades.reduce((sum, grade) => sum + grade.score, 0) / grades.length
    ).color,
    backgroundColor: getGradeColors(
      grades.reduce((sum, grade) => sum + grade.score, 0) / grades.length
    ).background
  }}>
    {Math.round(grades.reduce((sum, grade) => sum + grade.score, 0) / grades.length)}%
  </div>



  <div style={{
        position: 'absolute',
        top: '30px',right: '18%',
        display: 'flex',
        alignItems: 'center',
        zIndex: 10
      }}>
        <input
          type="text"
          placeholder="Search by Assignment Name..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{
            width: '300px',
            height: '35px',
            fontSize: '16px',
            border: '1px solid lightgrey',
            padding: '0px 40px 0px 20px',
            backgroundColor: 'white',
            color: 'black',
            borderRadius: '5px',
            outline: 'none',
            fontFamily: "'Montserrat', sans-serif"
          }}
        />
        <button
          onClick={() => setSearchTerm('')}
          style={{
            marginLeft: '-40px',
            backgroundColor: 'white',
            border: 'none',
            cursor: 'pointer',
            padding: '0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <Search size={20} strokeWidth={2.5} color={'grey'} />
        </button>
      </div>

        </div>

   
        <div style={{height: '130px', width: '80%',}}/> 


        {!searchTerm && <GradeProgressionChart grades={grades} />}




        {filteredGrades.length === 0 ? (
          <div style={{
            textAlign: 'left',
            fontSize: '20px',
            paddingLeft: '5.5%',
            width: '100%', 
            paddingTop: '40px',
            color: 'grey',
            marginTop: '150px'
          }}>
            No grades found.
          </div>
        ) : (
          <ul style={{
            padding: 0,
            marginTop: '0px',
            listStyleType: 'none',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px'
          }}>
            {filteredGrades.map(grade => {
              const isAMCQ = grade.type === 'AMCQ';
              const isOE = grade.type === 'OE';
              const isMCQ = grade.type === 'MCQ';
              const percentage = Math.round(
                isAMCQ
                  ? grade.SquareScore
                  : isMCQ
                    ? (grade.rawTotalScore / grade.maxRawScore) * 100
                    : grade.percentageScore
              );
              const letterGrade = getLetterGrade(percentage);

              // Determine grade colors based on percentage
              const getGradeColors = (grade) => {
                if (grade === undefined || grade === null) return { color: 'grey', background: '#f4f4f4' };
                if (grade < 50) return { color: '#FF0000', background: '#FFCBCB' };
                if (grade < 70) return { color: '#FF4400', background: '#FFC6A8' };
                if (grade < 80) return { color: '#EFAA14', background: '#FFF4DC' };
                if (grade < 90) return { color: '#9ED604', background: '#EDFFC1' };
                if (grade > 99) return { color: '#E01FFF', background: '#F7C7FF' };
                return { color: '#2BB514', background: '#D3FFCC' };
              };

              const gradeColors = getGradeColors(grade.score);

              return (
                <li
                  key={grade.id}
                  style={{
                    backgroundColor: hoveredGradeId === grade.id ? '#FBFEFF' : 'white',
                    borderTop: '1px solid #ededed',
                    borderRadius: '10px',
                    marginBottom: '-10px',
                    padding: '15px 4%',
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    transition: 'background-color 0.3s',
                    position: 'relative'
                  }}
                  onMouseEnter={() => setHoveredGradeId(grade.id)}
                  onMouseLeave={() => setHoveredGradeId(null)}
                  onClick={() => navigateToResults(grade.assignmentId, grade.type)}
                >
                  {/* Assignment Details */}
                  <div style={{ display: 'flex', alignItems: 'center',  width: '50%' }}>
                    {/* Assignment Name */}
                    <div style={{
                      fontSize: '16px',
                      fontWeight: '500',
                      textAlign: 'left',
                      maxWidth: '400px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      color: '#2c2c2c'
                    }}>
                      {grade.assignmentName}
                    </div>

                    {/* Letter Grade */}
                    <div style={{
                      fontSize: '16px',
                      fontWeight: '500',
                      borderRadius: '5px',
                      padding: '5px 10px',
                      marginLeft: 'auto',
                      marginRight: '20%',
                      textAlign: 'center',
                      width: '40px'
                    }}>
                      {letterGrade}
                    </div>

                    {/* Percentage */}
                    <div style={{
                      fontSize: '14px',
                      fontWeight: '500',
                      color: gradeColors.color,
                      backgroundColor: gradeColors.background,
                      borderRadius: '5px',
                      padding: '5px 5px',
                      textAlign: 'center',
                      width: '40px'
                    }}>
                      {grade.score}
                    </div>
                  </div>

                  {/* Submission Date and Format */}
                  <div style={{ display: 'flex', alignItems: 'center', gap: '20px', width: '40%' }}>
                    {/* Submission Date */}
                    <div style={{
                      fontSize: '16px',

                      color: 'grey',
                      fontStyle: 'italic',
                      fontWeight: '500',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '5px'
                    }}>
                      <SquareCheck size={20} color="#00DE09" />
                      {grade.submittedAt
                        ? new Date(grade.submittedAt.toDate()).toLocaleString(undefined, {
                          year: 'numeric',
                          month: 'short',
                          day: 'numeric',
                          hour: '2-digit',
                          minute: '2-digit',
                          hour12: true,
                        })
                        : 'N/A'}
                    </div>

                    {/* Format Type */}
                    <div style={{
                      fontSize: '16px',
                      fontWeight: '600',
                      marginLeft: 'auto',
                      color: isAMCQ ? '#2BB514' : isOE ? '#020CFF' : '#2BB514',
                      textAlign: 'right',
                      width: '60px'
                    }}>
                      {isAMCQ ? 'MC*' : isOE ? 'OE' : 'MC'}
                    </div>

                    {/* View Icon */}
                  
                  </div>
                </li>
              );
            })}
          </ul>
        )}
      </div>
    </div>
  );
}

export default TeacherStudentGrades;
